import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components/macro";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import CreateQuestionModal from "./CreateQuestionModal";
import { AutoScrollContext } from "../contexts/AutoScrollContext";
import Slider from "./UI/Slider";
import { device } from "./UI/Media";
import SignUpModal from "./SignUpModal";
import LoginModal from "./LoginModal";

const NavbarContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    min-height: 100vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 20%;
    @media only screen and (min-width: 320px) {
      padding: 0.5rem;
    }
  }
`;
const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: black;
  width: 10.5rem;
`;

const Title = styled.h1`
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;

  @media (min-width: 1000px) {
    font-size: 1.5rem;
  }
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;

  a {
    width: 100%;
  }
`;
const MenuItem = styled.button`
  background-color: #333333;
  margin: 0.5rem 0;
  color: #ffffff;
  font-size: 0.5rem;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 2rem;
  width: 100%;
  a {
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    background-color: #555555;
  }

  &:active {
    transform: translateY(2px);
  }

  @media only screen and (min-width: 320px) {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }
`;

const SignOutModal = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  max-width: 400px; /* Adjust the max-width as needed */
  height: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.2);
  border: 3px solid #333333;
  border-radius: 5px 5px 5px 5px;
  padding: 1rem;
  z-index: 2;
  button {
    margin: 0.25rem;
  }
`;

const AutoScrollDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-weight: 900;

  p {
    padding-right: 0.3rem;
  }

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Navbar: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const navMenuModalRef = useRef<HTMLDivElement>(null);
  const menuItemRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { currentUser, signOut } = useAuth();
  const { updateAutoScroll, autoScroll } = useContext(AutoScrollContext);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navMenuModalRef.current &&
        menuItemRef.current &&
        !navMenuModalRef.current.contains(event.target as Node) &&
        !menuItemRef.current.contains(event.target as Node)
      ) {
        setShowProfileOptions(false);
      }
    };

    const handleWindowClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowProfileOptions(false);
      }
    };

    if (showProfileOptions) {
      window.addEventListener("click", handleWindowClick);
      window.addEventListener("keydown", handleEscapeKeyPress);
    }

    return () => {
      window.removeEventListener("click", handleWindowClick);
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [showProfileOptions]);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleSignUp = () => {
    setShowLoginModal(false);
    setShowSignUpModal(true);
  };

  return (
    <>
      <NavbarContainer>
        <StyledLink to="/">
          <Title>WonderPoll</Title>
        </StyledLink>
        <Menu>
          <MenuItem onClick={toggleModal}>Create</MenuItem>
          <Link to="/explore">
            <MenuItem>Explore</MenuItem>
          </Link>
          {!currentUser && (
            <MenuItem
              onClick={() => {
                setShowLoginModal((prevValue) => !prevValue);
              }}
            >
              Login
            </MenuItem>
          )}

          {currentUser && (
            <>
              {/* <Link to="/account">
                <MenuItem>Account</MenuItem>
              </Link> */}
              <Link to="/dashboard">
                <MenuItem>Dashboard</MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  setShowSignOutModal(!showSignOutModal);
                }}
              >
                Sign Out
              </MenuItem>
            </>
          )}
        </Menu>

        <AutoScrollDiv>
          <p>AutoScroll:</p>
          <Slider onToggle={updateAutoScroll} value={autoScroll} />
        </AutoScrollDiv>
      </NavbarContainer>

      {showModal && <CreateQuestionModal setShowModal={setShowModal} />}

      {showSignUpModal && <SignUpModal setShowSignUpModal={setShowSignUpModal} />}
      {showLoginModal && <LoginModal handleSignUp={handleSignUp} setShowLoginModal={setShowLoginModal} />}

      {showSignOutModal && (
        <SignOutModal>
          <p>Are you sure you want to sign out?</p>
          <div>
            <button
              onClick={async () => {
                await signOut();
                navigate(0);
              }}
            >
              Sign Out
            </button>
            <button
              onClick={() => {
                setShowSignOutModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </SignOutModal>
      )}
    </>
  );
};

export default Navbar;
