import React, { useState } from "react";
import { signInWithPopup, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase";

function Signup() {
  const { currentUser, signOut } = useAuth();

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
  };

  return <div></div>;
}

export default Signup;
