import styled from "styled-components";

export const SelectDiv = styled.div`
  position: sticky;
  z-index: 5;
  margin: 2rem 1rem 1rem 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  box-sizing: border-box;
  background-color: #e0f1fc;

  &:nth-of-type(2) {
    margin-top: -0.5rem;
  }
`;
export const OptionsDiv = styled.div<{ active: boolean }>`
  display: flex;
  align-content: center;
  align-items: center; /* Center the content vertically */
  flex-direction: column;
  width: 100%;
  max-height: ${(props) => (props.active ? "100vh" : "0px")};
  overflow-y: scroll; /* Enable vertical scrolling if needed */
  transition: max-height 1s, padding-bottom 1s;
  z-index: 5;
`;

export const Option = styled.div`
  list-style: none;
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  width: 100%;
  /* border-radius: 8px; */
  border-bottom: 1px solid lightgray;

  outline: none;
  transition: 1s;
  font-weight: 900;
  appearance: none;
  background-color: white;

  font-size: 1rem;
  position: relative;
  z-index: 5;
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.6) 0px 2px 8px 0px;
    background-color: #f0efef;
  }
  &:focus {
    box-shadow: rgba(60, 64, 67, 0.6) 0px 2px 8px 0px, rgba(60, 64, 67, 0.3) 0px 8px 16px 0px;
  }
`;
