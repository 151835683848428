import { RefObject, useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components/macro";
import { Answer } from "../types";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { database } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { getUserVote, updateKarma, updateUserVotes } from "../Utils/userUtils";
import Button from "./UI/Button";
import { device } from "./UI/Media";
import { AutoScrollContext } from "../contexts/AutoScrollContext";
import { useLocation } from "react-router-dom";
import Toast from "./UI/Toast";

type ComponentProps = {
  answers: Answer[];
  questionId: string;
  totalVotes?: number;
  hasUserVoted: boolean;
  questionRef: RefObject<HTMLDivElement>;
};
type AnswerDivProps = {
  votedFor: boolean;
  hasUserVoted: boolean;
  loggedIn: boolean;
};

type BarProps = {
  votedFor: boolean;

  percentage: number;
  hasUserVoted: boolean;
};

const Container = styled.div`
  width: 100%;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    /* padding-right: 1rem; */
  }
`;
const AnswerDiv = styled.div<AnswerDivProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: flex-start;
  padding-bottom: 0;
  padding-right: 0.5rem;
  font-weight: 700;
  margin: 0.25rem;
  box-shadow: #259500(50, 50, 93, 0.25) 0px 2px 5px -1px, #9cf97d(0, 0, 0, 0.3) 0px 1px 3px -1px;
  opacity: ${(props) => `${props.votedFor ? "1" : "0.8"}`};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const AnswerDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0rem 0.25rem;
`;
const AnswerText = styled.p`
  font-weight: 800;
  font-size: 1rem;
  padding-right: 0.25rem;
`;
const VoteText = styled.p`
  font-weight: 500;
  font-size: 0.7rem;
  padding: 0.25rem 0;
`;

const BarCasing = styled.div<{ votedFor: boolean }>`
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px inset;
  margin: 0.25rem;
  /* border: ${(props) => `${props.votedFor ? "#ffc342 2px solid" : "#5cb0e7"}`}; */
`;

const Bar = styled.div<BarProps>`
  transition: width 0.2s ease-in-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: ${(props) => `${props.percentage}%`};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 2rem;
  background: ${(props) => `${props.votedFor ? "linear-gradient(135deg, #4a90e2, #9013fe)" : "black"}`};

  p {
    padding-right: 0.5rem;
    font-size: 0.75rem;
    color: white;
  }
`;

const ResultsButton = styled(Button)`
  width: 16rem;
  /* padding-top: 10px; */
  align-self: center;
  @media ${device.tablet} {
    width: 16rem;
  }
`;

const Radio = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
`;

function Answers({ answers, questionId, totalVotes, hasUserVoted, questionRef }: ComponentProps) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [userVote, setUserVote] = useState<string>();
  const [toastMessage, setToastMessage] = useState<string>("");
  const { autoScroll } = useContext(AutoScrollContext);
  const location = useLocation();

  useEffect(() => {
    if (hasUserVoted && currentUser) {
      getUserVote(currentUser.uid, questionId)
        .then((vote) => {
          setUserVote(vote);
        })
        .catch((error) => {
          // Handle any errors that occur during the promise resolution
          console.error(error);
        });
    }
  }, [currentUser, hasUserVoted]);

  const handleVote = async (answerId: string, questionId: string) => {
    setLoading(true);

    const userAnswerId = await getUserVote(currentUser.uid, questionId);

    const docRef = doc(database, "Questions", questionId);

    // Retrieve the document and update the specific element
    const documentSnapshot = await getDoc(docRef);
    if (documentSnapshot.exists()) {
      const documentData = documentSnapshot.data();

      const newAnswers = documentData.answers.map((searchAnswer: Answer) => {
        if (hasUserVoted) {
          if (userAnswerId === searchAnswer.id) {
            documentData.totalVotes -= 1;
            searchAnswer.votes -= 1;
            if (currentUser.uid !== documentData.userId) {
              updateKarma(documentData.userId, -1);
            }
            return searchAnswer;
          }
          if (answerId === searchAnswer.id) {
            documentData.totalVotes += 1;
            searchAnswer.votes += 1;
            if (currentUser.uid !== documentData.userId) {
              updateKarma(documentData.userId, 1);
            }

            return searchAnswer;
          }
          return searchAnswer;
        } else {
          if (answerId === searchAnswer.id) {
            documentData.totalVotes += 1;
            searchAnswer.votes += 1;
            if (currentUser.uid !== documentData.userId) {
              updateKarma(documentData.userId, 1);
            }
            return searchAnswer;
          }
          return searchAnswer;
        }
    
      });

      // Update the entire document with the modified array
      await updateDoc(docRef, { ...documentData, answers: newAnswers });
      await updateUserVotes(currentUser.uid, questionId, answerId, setUserVote);

      if (autoScroll && location.pathname.includes("explore")) {
        scrollToNextCard();
      }

      console.log("Document successfully updated!");
    } else {
      console.log("Document does not exist.");
    }
    setLoading(false);
  };

  const scrollToNextCard = () => {
    if (questionRef.current && questionRef.current.nextElementSibling) {
      questionRef.current.nextElementSibling.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  };

  return (
    <Container>
      {answers
        // .sort((a, b) => {
        //   return b.votes - a.votes;
        // })
        .map((answer, index) => {
          return (
            <AnswerDiv
              loggedIn={currentUser}
              votedFor={userVote === answer.id}
              hasUserVoted={hasUserVoted}
              key={index}
              onClick={() => {
                if (!loading && currentUser) {
                  handleVote(answer.id, questionId);
                  setToastMessage(`You voted for ${answer.answer}`);
                }
                if (!currentUser) {
                  setToastMessage(`Please log in to vote!`);
                }
              }}
            >
              {toastMessage && <Toast toastMessage={toastMessage} />}

              <AnswerDetails>
                <Radio type="radio" checked={userVote === answer.id} readOnly />

                <AnswerText>{answer.answer}</AnswerText>
                {hasUserVoted && (
                  <>
                    <VoteText>{`(${answer.votes} votes)`}</VoteText>
                  </>
                )}
              </AnswerDetails>

              
              {hasUserVoted && totalVotes && (
                <BarCasing votedFor={userVote === answer.id}>
                  <Bar percentage={(answer.votes / totalVotes) * 100} hasUserVoted={hasUserVoted} votedFor={userVote === answer.id}>
                    {Math.round((answer.votes / totalVotes) * 100) !== 0 ? <p>{`${Math.round((answer.votes / totalVotes) * 100)}%`}</p> : null}
                  </Bar>
                </BarCasing>
              )}
            </AnswerDiv>
          );
        })}

      {!hasUserVoted &&
        currentUser &&
        (totalVotes !== 0 ? (
          <ResultsButton
            onClick={() => {
              handleVote("0", questionId);
            }}
          >
            Show Results
          </ResultsButton>
        ) : (
          <p style={{ textAlign: "center" }}>Be the first one to vote</p>
        ))}
    </Container>
  );
}

export default Answers;
