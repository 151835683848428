import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { AutoScrollProvider } from "./contexts/AutoScrollContext";
import { ToastProvider } from "./contexts/ToastContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <AuthProvider>
    <AutoScrollProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </AutoScrollProvider>
  </AuthProvider>
);
