import React from "react";
import styled, { keyframes } from "styled-components"
import Task from "./Task";

import bookmark from "../assets/icons/bookmark.svg";
import copy from "../assets/icons/copy.svg";

import twitter from "../assets/icons/twitter-share.svg";
import reddit from "../assets/icons/reddit-share.svg";
import facebook from "../assets/icons/facebook-share.svg";
import linkedin from "../assets/icons/linkedin-share.svg";

import { Question } from "../types";


const BarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
`;

interface TaskBarProps {
  question: Question;
}

const TaskBar: React.FC<TaskBarProps> = ({ question }) => {
  const handleTaskClick = (taskId: number) => {
    // Handle task click event here
    console.log(`Task ${taskId} clicked.`);
  };

  function openShareDialog(url: string) {
    const width = 550;
    const height = 420;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    window.open(url, "Share", `width=${width}, height=${height}, left=${left}, top=${top}`);
  }

  const handleTwitterShare = () => {
    const pollUrl = `localhost:3000/poll/${question.id}`;
    const introText = "Check out this poll: ";
    const pollQuestion = question.question;

    // Share on Twitter
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(introText + pollQuestion)}&url=${encodeURIComponent(
      pollUrl
    )}&via=WonderPollApp&hashtags=wonderpoll`;
    // Open share dialog or new window for each platform
    openShareDialog(twitterShareUrl);
  };

  const handleRedditShare = () => {
    const pollUrl = `localhost:3000/poll/${question.id}`;
    const introText = "Check out this poll: ";
    const pollQuestion = question.question;

    const redditShareUrl = `https://www.reddit.com/submit/?url=${encodeURIComponent(pollUrl)}&title=${encodeURIComponent(introText + pollQuestion)}`;

    openShareDialog(redditShareUrl);
  };

  const handleFacebookShare = () => {
    const pollUrl = `localhost:3000/poll/${question.id}`;
    const introText = "Check out this poll: ";
    const pollQuestion = question.question;

    // Share on Facebook
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pollUrl)}`;
    // Open share dialog or new window for each platform
    openShareDialog(facebookShareUrl);
  };

  const handleLinkedInShare = () => {
    const pollUrl = `localhost:3000/poll/${question.id}`;
    const introText = "Check out this poll: ";
    const pollQuestion = question.question;

    // Share on LinkedIn
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(pollUrl)}&title=${encodeURIComponent(
      introText + pollQuestion
    )}`;
    // Open share dialog or new window for each platform
    openShareDialog(linkedInShareUrl);
  };

  return (
    <BarContainer>
      <Task imgSrc={twitter} onClick={() => handleTwitterShare()} />
      <Task imgSrc={reddit} onClick={() => handleRedditShare()} />
      <Task imgSrc={facebook} onClick={() => handleFacebookShare()} />
      <Task imgSrc={linkedin} onClick={() => handleLinkedInShare()} />
    </BarContainer>
  );
};

export default TaskBar;
