import { doc, getDoc, updateDoc } from "firebase/firestore";
import styled from "styled-components/macro";
import { database } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useCallback, useEffect, useState } from "react";
import { Question, UserVote } from "../types";
import QuestionCard from "../components/QuestionCard";
import Loading from "../components/UI/Loading";
import { device } from "../components/UI/Media";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { useNavigate } from "react-router-dom";

import camera from "../../src/assets/icons/camera-fill.svg";

type ListItemProps = {
  active: boolean;
};

const Container = styled.div`
  width: 100%;
  text-align: center;
  height: fit-content;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media ${device.tablet} {
    margin-top: 0;
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 2rem 2rem 0 2rem; */
  padding: 2rem 0rem 0rem 0rem;
  p {
    font-weight: 500;
    font-size: 0.8rem;
    color: #00000082;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Image = styled.img`
  position: relative;
  height: 6rem;
  width: 6rem;
  border-radius: 50px;
  margin: 1rem 0;
  cursor: pointer;
`;

const CameraIcon = styled.img`
  position: absolute;
  bottom: 15px;
  right: 5.3px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  padding: 3px;
  background-color: white;
  cursor: pointer;
`;

const Username = styled.h2`
  cursor: pointer;
`;

const ListContainer = styled.div`
  position: sticky;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  padding: 1.5rem 0 1.5rem 0;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  z-index: 10;

  @media (min-width: 768px) {
  }
  @media (min-width: 1200px) {
    width: 1000px;
  }
`;

const ListItem = styled.div<ListItemProps>`
  padding: 15px;
  z-index: 100;
  text-align: center;
  font-weight: ${(props) => (props.active ? "700" : "400")};
  background: ${(props) => (props.active ? "linear-gradient(135deg, #4a90e2, #9013fe)" : "black")};
  border: solid 1px white;
  color: white;
  width: 100%;
  cursor: pointer;
  @media (min-width: 768px) {
  }
  :hover {
  }
`;

function DashboardPage() {
  const { currentUser, updateUsername, updatePhoto } = useAuth();
  const [fetchedPolls, setFetchedPolls] = useState<Question[]>();
  const [loading, setLoading] = useState(false);
  const [karma, setUserKarma] = useState(0);

  const [username, setUsername] = useState(currentUser.displayName);
  const [editNameActive, setEditNameActive] = useState(false);

  const [userPhoto, setUserPhoto] = useState<File | null>(null);

  const [userPhotoUrl, setUserPhotoUrl] = useState<string>();

  const [editPhotoActive, setEditPhotoActive] = useState(false);
  const [activeList, setActiveList] = useState<string>("voted");
  const navigate = useNavigate();
  const handleUsernameChange = (event: any) => {
    setUsername(event.target.value);
  };

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setUserPhoto(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUserPhotoUrl(imageUrl);
    } else {
      setUserPhotoUrl("");
    }
  };

  const handlePhotoUpdate = async () => {
    try {
      if (userPhoto) {
        const storageRef = ref(storage, `userPhotos/${currentUser.uid}/${userPhoto.name}`);
        await uploadBytes(storageRef, userPhoto);
        const photoURL = await getDownloadURL(storageRef);

        // Update the user's photoURL in Firebase authentication
        await updatePhoto(photoURL);

        window.location.reload();
      }
    } catch (error) {
      console.log("Error updating user photo: ", error);
    }
  };

  const updateUsernamesInDatabase = async (username: string) => {
    const usersRef = doc(database, "Users", currentUser.uid);
    const usersSnapshot = await getDoc(usersRef);

    if (usersSnapshot.exists()) {
      const data = usersSnapshot.data();
      const { createdPolls } = data;

      if (createdPolls) {
        for (const id of createdPolls) {
          const questionsRef = doc(database, "Questions", id);
          const questionsSnapshot = await getDoc(questionsRef);

          if (questionsSnapshot.exists()) {
            const questionData = questionsSnapshot.data() as Question;
            questionData.username = username;

            await updateDoc(questionsRef, questionData); // Update the question data
          }
        }
      }
    }

    fetchPolls(activeList);
  };

  const fetchPolls = useCallback(
    async (category: string) => {
      setActiveList(category);
      setLoading(true);
      const usersRef = doc(database, "Users", currentUser.uid);
      const usersSnapshot = await getDoc(usersRef);

      if (usersSnapshot.exists()) {
        const data = usersSnapshot.data();
        let pollIds;

        if (data && data.karma) {
          setUserKarma(data.karma);
        }

        if (category === "voted") {
          if (data && data.votes) {
            pollIds = data.votes.map((vote: UserVote) => {
              return vote.questionId;
            });
          }
        }

        if (category === "saved") {
          if (data && data.saved) {
            pollIds = data.saved.map((id: string) => {
              return id;
            });
          }
        }

        if (category === "created") {
          if (data && data.createdPolls) {
            pollIds = data.createdPolls.map((id: string) => {
              return id;
            });
          }
        }

        let polls: Question[] = [];

        {
          if (pollIds) {
            for (const id of pollIds) {
              const questionsRef = doc(database, "Questions", id);
              const questionsSnapshot = await getDoc(questionsRef);

              if (questionsSnapshot.exists()) {
                const questionData = questionsSnapshot.data() as Question;
                polls.push(questionData);
              }
            }
          }
        }
        setFetchedPolls(polls);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [currentUser]
  );

  useEffect(() => {
    fetchPolls("voted");
  }, [fetchPolls]);

  return (
    <Container>
      <UserInfo>
        {/* <div> */}
        {currentUser.photoURL && (
          <>
            {editPhotoActive && (
              <>
                <input type="file" accept="image/*" onChange={handlePhotoChange} />
                {userPhotoUrl && <Image src={userPhotoUrl} alt="Selected Photo" />}
                <button onClick={handlePhotoUpdate}>Confirm</button>
                <button onClick={() => setEditPhotoActive(false)}>Cancel</button>
              </>
            )}
            <ImageContainer>
              <Image
                onClick={() => {
                  setEditPhotoActive(true);
                }}
                src={currentUser.photoURL}
                alt="User Photo"
              />
              <CameraIcon
                onClick={() => {
                  setEditPhotoActive(true);
                }}
                src={camera}
                alt="Camera Icon"
              />
            </ImageContainer>
          </>
        )}
        <Username
          onClick={() => {
            setEditNameActive(true);
          }}
        >
          {username}
        </Username>
        {editNameActive && (
          <>
            <input type="text" value={username} onChange={handleUsernameChange} />
            <button
              onClick={async () => {
                await updateUsername(username);
                updateUsernamesInDatabase(username);
                setEditNameActive(false);
              }}
            >
              Confirm
            </button>
            <button
              onClick={async () => {
                setEditNameActive(false);
              }}
            >
              Cancel
            </button>
          </>
        )}
        {/* </div> */}
        <p>KarmaCoins: {karma}</p>
      </UserInfo>

      <ListContainer>
        <ListItem
          active={activeList === "voted"}
          onClick={() => {
            if (activeList !== "voted") {
              setLoading(true);
              fetchPolls("voted");
            }
          }}
        >
          <p>Voted On</p>
        </ListItem>
        <ListItem
          active={activeList === "created"}
          onClick={() => {
            if (activeList !== "created") {
              setLoading(true);
              fetchPolls("created");
            }
          }}
        >
          <p>Your Polls</p>
        </ListItem>
        <ListItem
          active={activeList === "saved"}
          onClick={() => {
            if (activeList !== "saved") {
              setLoading(true);
              fetchPolls("saved");
            }
          }}
        >
          <p>Bookmarked</p>
        </ListItem>
      </ListContainer>

      {loading ? (
        <AnimationContainer>
          <Loading />
        </AnimationContainer>
      ) : fetchedPolls && fetchedPolls?.length > 0 ? (
        fetchedPolls.map((poll) => <QuestionCard key={poll.id} question={poll} />)
      ) : (
        <p>No polls found.</p>
      )}
    </Container>
  );
}
export default DashboardPage;
