import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { database } from "../firebase";
import { UserVote } from "../types";

export const getUserVote = async (userId: string, questionId: string) => {
  const usersRef = doc(database, "Users", userId);
  let output = "";
  const usersSnapshot = await getDoc(usersRef);

  if (usersSnapshot.exists()) {
    const votes = usersSnapshot.data().votes;
    const votedAnswer = votes.find((vote: UserVote) => vote.questionId === questionId);

    if (votedAnswer) {
      output = votedAnswer.answerId;
    }
  }

  return output;
};

export const updateUserVotes = async (userId: string, questionId: string, answerId: string, setUserVote: Function) => {
  const usersRef = doc(database, "Users", userId);

  const usersSnapshot = await getDoc(usersRef);

  if (usersSnapshot.exists()) {
    const userData = usersSnapshot.data();
    const userVotes: UserVote[] = userData.votes || [];

    // Check if user (userId) has voted on current poll (questionId)
    const existingVoteIndex = userVotes.findIndex((vote: UserVote) => {
      return vote.questionId === questionId;
    });

    if (existingVoteIndex !== -1) {
      if (userVotes[existingVoteIndex].answerId === answerId) {
        // User clicked on the same answer, remove the vote
        userVotes.splice(existingVoteIndex, 1);
        userVotes.push({ questionId, answerId: "0" });
        setUserVote("0");
      } else {
        // User clicked on a different answer, update the vote
        userVotes[existingVoteIndex].answerId = answerId;
        setUserVote(answerId);
      }
    } else {
      // User hasn't voted on the current poll, add a new vote
      userVotes.push({ questionId, answerId });
      setUserVote(answerId);
    }

    await updateDoc(usersRef, { votes: userVotes });
  } else {
    const userVotes: UserVote[] = [{ questionId, answerId }];
    await setDoc(usersRef, { votes: userVotes });
  }
};

export const updateKarma = async (userId: string, voteAmount: number) => {
  const usersRef = doc(database, "Users", userId);

  const usersSnapshot = await getDoc(usersRef);

  if (usersSnapshot.exists()) {
    const userData = usersSnapshot.data();
    let userKarma: number = userData.karma || 0;

    userKarma += voteAmount;
    // Check if user (userId) has voted on current poll (questionId)

    await updateDoc(usersRef, { karma: userKarma });
  } else {
    await setDoc(usersRef, { karma: voteAmount });
  }
};
