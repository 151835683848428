import React, { createContext, useEffect, useState } from "react";

interface AutoScrollContextProps {
  autoScroll: boolean;
  setAutoScroll: (value: boolean) => void;
  updateAutoScroll: () => void;
}

export const AutoScrollContext = createContext<AutoScrollContextProps>({
  autoScroll: false,
  setAutoScroll: () => {},
  updateAutoScroll: () => {},
});

export const AutoScrollProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [autoScroll, setAutoScroll] = useState<boolean>(() => {
    const storedAutoScroll = localStorage.getItem("autoScroll");
    return storedAutoScroll ? JSON.parse(storedAutoScroll) : false;
  });

  useEffect(() => {
    localStorage.setItem("autoScroll", JSON.stringify(autoScroll));
  }, [autoScroll]);

  const updateAutoScroll = () => {
    setAutoScroll(!autoScroll);
  };

  return <AutoScrollContext.Provider value={{ autoScroll, setAutoScroll, updateAutoScroll }}>{children}</AutoScrollContext.Provider>;
};
