import styled from "styled-components";
import { useAuth } from "../contexts/AuthContext";
import { useRef } from "react";
import { Label, ModalInput } from "./CreateQuestionModal";
import Button from "./UI/Button";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  max-width: 90%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  background-color: #ffffff;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.2);
  border: 3px solid #333333;
  border-radius: 5px 5px 5px 5px;
  padding: 1rem;
  z-index: 10;

  @media (min-width: 786px) {
    left: 55%;
    top: 25%;
    transform: translate(-50%, -25%);
    max-width: 50%;
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  label {
    margin-bottom: 4px;
  }

  input {
    margin-bottom: 0.5rem;
  }

  button {
    margin: 0.25rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

type Props = {
  setShowSignUpModal: Function;
};
function SignUpModal({ setShowSignUpModal }: Props) {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const EmailRef = useRef<HTMLInputElement>(null);
  const PasswordRef = useRef<HTMLInputElement>(null);
  const NameRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <InfoContainer>
        <h2>Create Account</h2>

        <Label>Email</Label>
        <ModalInput type="email" placeholder="Enter Email" ref={EmailRef} required />

        <Label>Password</Label>
        <ModalInput type="Password" placeholder="Enter Password" ref={PasswordRef} required />

        <Label>Name</Label>
        <ModalInput type="text" placeholder="Enter Your Name" ref={NameRef} required />

        <Button
          onClick={async () => {
            if (EmailRef.current?.value && PasswordRef.current?.value && NameRef.current?.value)
              await signUp(EmailRef.current?.value, PasswordRef.current?.value, NameRef.current?.value);
            setShowSignUpModal(false);
            navigate("explore");
          }}
        >
          Create Account
        </Button>
      </InfoContainer>
    </Container>
  );
}
export default SignUpModal;
