import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDsQHDP0ijOjGqb8lpGjKUSZhzyg7k3poE",
  authDomain: "ask-app-466fe.firebaseapp.com",
  projectId: "ask-app-466fe",
  storageBucket: "ask-app-466fe.appspot.com",
  messagingSenderId: "417169967610",
  appId: "1:417169967610:web:645cda57c74b7114b7fc43",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const database = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { firebaseApp, auth, database, storage };
