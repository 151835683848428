import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  toastMessage: string;
};

const slideInSlideOut = keyframes`
  0% {
    transform: translateY(-100%);
  }
  10%, 90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

export const ToastAlert = styled.div`
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  background: linear-gradient(135deg, #4a90e2, #9013fe);
  color: white;
  font-weight: 800;
  z-index: 1000000;
  animation: ${slideInSlideOut} 2s forwards ease-in-out;
`;

export const ToastMessage = styled.p`
  margin: 0;
  padding: 1rem;
  text-align: center;
`;

function Toast({ toastMessage }: Props) {
  const [showToast, setShowToast] = useState(true);

  useEffect(() => {
    setShowToast(true); // Show the toast whenever a new message is received
    const timeoutId = setTimeout(() => {
      setShowToast(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []); //

  return showToast ? (
    <ToastAlert>
      <ToastMessage>{toastMessage}</ToastMessage>
    </ToastAlert>
  ) : (
    <></>
  );
}
export default Toast;
