import { getTimeSinceCommentPosted } from "../Utils/dateUtil";
import styled from "styled-components/macro";
import { Comment } from "../types";
import { database } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import deleteIcon from "../assets/icons/deleteCommentIcon.svg";
import { useAuth } from "../contexts/AuthContext";
import { useEffect, useRef, useState } from "react";
import { handleCommentDelete, handleCommentReply } from "../Utils/commentUtils";

import emptyLikeIcon from "../assets/icons/empty-heart.svg";
import filledLikeIcon from "../assets/icons/filled-heart.svg";
import ReplyArrow from "../assets/icons/reply-arrow.svg";
import { updateKarma } from "../Utils/userUtils";

type Props = {
  comment: Comment;
  questionComments?: Comment[];
  questionId: string;
};

type LikeButtonProps = {
  active: boolean;
};

const CommentContainer = styled.div`
  padding-top: 1rem;
  width: 100%;
  display: flex;
  border-radius: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Username = styled.h4`
  font-size: 1rem;
`;
const DateP = styled.p`
  font-size: 0.65rem;
  font-style: italic;
  padding-left: 0.5rem;
`;
const CommentText = styled.p`
  padding: 0.25rem 0;
  color: #4a4a4a;
`;
const DeleteCommentIcon = styled.img`
  margin-left: 1rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  &:hover {
    filter: brightness(75%);
  }
`;
const ReplyContainer = styled.div`
  /* border: solid 1px red; */
`;
const CommentBin = styled.div`
  display: flex;
  align-items: center;
`;
const ReplyIcon = styled.img`
  height: 1.25rem;
  margin-left: 0.25rem;
  padding-top: 0.25rem;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
const SubmitButton = styled.button`
  margin-left: 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 900;
  padding: 0.5rem;
  height: 2.25rem;
  color: white;
  background-color: #007bcd;
  border: 1px solid #333333;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: #0061a2;
  }
  @media (min-width: 768px) {
    width: 15%;
    margin-right: 1.5rem;
  }
`;
const ReplyInput = styled.input`
  box-sizing: border-box;
  box-sizing: border-box;
  width: 92%;
  height: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid rgb(51, 51, 51, 0.2);
  border-radius: 0.25rem;

  &::placeholder {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
  }
  @media (min-width: 768px) {
    width: 88%;
    align-items: flex-start;
  }
`;

const LikesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 1rem;
  padding-top: 0.15rem;
  margin-right: 0.35rem;
  height: 2rem;
  font-size: 10px;
`;

const LikeButton = styled.img<LikeButtonProps>`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const ReplyBin = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 0.5rem;
`;

function CommentCard({ comment, questionId }: Props) {
  const [replyActive, setReplyActive] = useState(false);
  const [userLiked, setUserLiked] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState("");

  const replyRef = useRef<HTMLInputElement>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && comment.likedByUsers) {
      if (comment.likedByUsers.includes(currentUser.uid)) {
        setUserLiked(true);
      } else {
        setUserLiked(false);
      }
    }
  }, [comment, currentUser]);

  const handleLikeVote = async (questionId: string, commentId: string, userLiked: boolean) => {
    try {
      const docRef = doc(database, "Questions", questionId);
      const documentSnapshot = await getDoc(docRef);

      const questionData = documentSnapshot.data();
      const comments = questionData?.comments;

      const updatedComments = updateComments(comments, commentId, currentUser.uid, userLiked);

      if (updatedComments) {
        const updatedQuestion = { ...questionData, comments: updatedComments };
        await updateDoc(docRef, updatedQuestion);
        console.log("Vote updated successfully!");
      } else {
        console.log("Comment not found.");
      }
    } catch (error) {
      console.error("Error updating vote:", error);
    }
  };

  function updateComment(comment: Comment, commentId: string, userId: string, userLiked: boolean): Comment {
    if (comment.id === commentId) {
      if (userLiked) {
        comment.likes -= 1;
        comment.likedByUsers = comment.likedByUsers.filter((likes) => likes !== userId);
        if (currentUser.uid !== comment.userId) {
          updateKarma(comment.userId, -1);
        }
      } else {
        comment.likes += 1; // Revert the previous downvote
        comment.likedByUsers.push(userId);
        if (currentUser.uid !== comment.userId) {
          updateKarma(comment.userId, 1);
        }
      }
    }

    const updatedChildren = comment.children.map((child) => updateComment(child, commentId, userId, userLiked));
    return {
      ...comment,
      children: updatedChildren,
    };
  }

  function updateComments(comments: Comment[], commentId: string, userId: string, userLiked: boolean): Comment[] {
    const updatedComments = comments.map((comment) => updateComment(comment, commentId, userId, userLiked));
    return updatedComments;
  }

  return (
    <CommentContainer>
      <LikesDiv>
        <LikeButton
          src={userLiked ? filledLikeIcon : emptyLikeIcon}
          active={userLiked}
          onClick={() => {
            handleLikeVote(questionId, comment.id, userLiked);
          }}
        />
        <p>{comment.likes}</p>
      </LikesDiv>

      <ContentContainer>
        <UserInfo>
          <Username>{comment.username}</Username>
          <DateP>{getTimeSinceCommentPosted(comment.date)}</DateP>
          {currentUser && comment.userId === currentUser.uid && (
            <>
              <DeleteCommentIcon src={deleteIcon} onClick={() => handleCommentDelete(questionId, comment.id)}></DeleteCommentIcon>
            </>
          )}
        </UserInfo>
        <CommentBin>
          <CommentText>{comment.content}</CommentText>

          {currentUser && (
            <ReplyContainer>
              <ReplyIcon
                src={ReplyArrow}
                alt="reply arrow icon"
                onClick={() => {
                  setReplyActive(!replyActive);
                }}
              ></ReplyIcon>
            </ReplyContainer>
          )}
        </CommentBin>
        {replyActive && (
          <ReplyBin>
            <ReplyInput ref={replyRef} placeholder="Add a reply" onChange={(e) => setCommentValue(e.target.value)} />
            <SubmitButton
              disabled={commentValue === ""}
              onClick={async () => {
                await handleCommentReply(questionId, currentUser, replyRef, comment.id);
                setReplyActive(false);
              }}
            >
              Reply
            </SubmitButton>
          </ReplyBin>
        )}

        {comment.children &&
          comment.children.map((childComment: Comment, i) => {
            return <CommentCard key={i} comment={childComment} questionId={questionId} questionComments={childComment.children}></CommentCard>;
          })}
      </ContentContainer>
    </CommentContainer>
  );
}

export default CommentCard;
