import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { database } from "../firebase";
import { Question } from "../types";
import QuestionCard from "../components/QuestionCard";
import { onSnapshot } from "firebase/firestore";
import { device } from "../components/UI/Media";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0 1rem;
  background-color: #e0f1fc;
  flex: 1;
  overflow-y: scroll;
  /* height: fit-content; */
  @media ${device.tablet} {
    padding: 1rem 0 1rem;
  }
`;

function PollPage() {
  const { id } = useParams();
  const [question, setQuestion] = useState<Question>();

  const getPoll = (id: string) => {
    const pollRef = doc(database, "Questions", id);

    const unsubscribe = onSnapshot(pollRef, (pollSnapshot) => {
      if (pollSnapshot.exists()) {
        const fetchedQuestion = pollSnapshot.data() as Question;
        setQuestion(fetchedQuestion);
      }
    });

    // Return an unsubscribe function to clean up the listener
    return unsubscribe;
  };

  useEffect(() => {
    if (id) {
      const unsubscribe = getPoll(id);

      // Clean up the listener when the component unmounts or id changes
      return () => unsubscribe();
    }
  }, [id]);

  return <Container>{question && <QuestionCard question={question}></QuestionCard>}</Container>;
}
export default PollPage;
