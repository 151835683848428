import styled from "styled-components/macro";
import { useContext, useEffect, useRef, useState } from "react";
import QuestionCard from "../components/QuestionCard";
import useFetchPolls from "../components/hooks/FetchDataHook";
import { device } from "../components/UI/Media";
import CreateQuestionModal from "../components/CreateQuestionModal";
import { Option, OptionsDiv, SelectDiv } from "../components/UI/Select";
import createQuestionFromAPI from "../Utils/createQuestionFromAPI";

const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 0 1rem;
  flex: 1;
  background-color: #e0f1fc;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (min-width: 768px) {
    padding-top: 0;
  }
`;

const MobileCreateButton = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  background-color: white;
  border-radius: 50px;
  bottom: 1rem;
  right: 1rem;
  border: 2px solid black;

  @media ${device.tablet} {
    display: none;
  }
`;

function ExplorePage() {
  const categoryList = ["All", "Sports", "Food", "Politics", "Health", "Music", "TV", "Movies", "Games", "Technology"];

  const filterList = ["Newest", "Oldest", "Popular"];

  const [activeCategory, setActiveCategory] = useState("");
  const [activeFilter, setActiveFilter] = useState<string>(() => {
    // Load the activeFilter from localStorage on initial render
    const storedFilter = localStorage.getItem("activeFilter");
    return storedFilter ? storedFilter : "Newest";
  });

  const [filterMenu, setFilterMenu] = useState(false);

  const [categoryMenu, setCategoryMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const categoryRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);

  const data = useFetchPolls("Questions", "category", activeCategory);

  useEffect(() => {
    const handleClickOutsideCategory = (event: any) => {
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        if (!event.target.classList.contains("selectCategory")) {
          setCategoryMenu(false);
        }
      }
    };

    const handleClickOutsideFilter = (event: any) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        if (!event.target.classList.contains("selectCategory")) {
          setFilterMenu(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutsideCategory);
    document.addEventListener("mousedown", handleClickOutsideFilter);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCategory);
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    };
  }, [activeCategory, categoryMenu]);

  useEffect(() => {
    // Update localStorage whenever activeFilter changes
    localStorage.setItem("activeFilter", activeFilter);
  }, [activeFilter]);

  // useEffect(() => {
  //   createQuestionFromAPI()
  // }, [])

  return (
    <>
      <ExploreContainer>
        <SelectDiv>
          <Option
            className="selectCategory"
            onClick={() => {
              setCategoryMenu(!categoryMenu);
            }}
          >
            {activeCategory ? activeCategory : "Select Category"}
          </Option>

          <OptionsDiv active={categoryMenu} ref={categoryRef}>
            {categoryList.map((categoryName) => {
              if (activeCategory !== categoryName) {
                return (
                  <Option
                    key={categoryName}
                    onClick={() => {
                      setActiveCategory(categoryName !== "All" ? categoryName : "");
                      setCategoryMenu(false);
                    }}
                  >
                    {categoryName}
                  </Option>
                );
              } else {
                return null;
              }
            })}
          </OptionsDiv>
        </SelectDiv>
        <SelectDiv>
          <Option
            className="selectCategory"
            onClick={() => {
              setFilterMenu(!filterMenu);
            }}
          >
            {activeFilter ? ` Sort by: ${activeFilter}` : "Select Filter"}
          </Option>

          <OptionsDiv active={filterMenu} ref={filterRef}>
            {filterList.map((filterName) => {
              if (activeFilter !== filterName) {
                return (
                  <Option
                    key={filterName}
                    onClick={() => {
                      setActiveFilter(filterName);
                      setFilterMenu(false);
                    }}
                  >
                    {filterName}
                  </Option>
                );
              }
            })}
          </OptionsDiv>
        </SelectDiv>

        {data &&
          data
            .sort((a, b) => {
              switch (activeFilter) {
                case "Newest":
                  return b.date - a.date;
                case "Oldest":
                  return a.date - b.date;
                case "Popular":
                  return b.totalVotes - a.totalVotes;
                default:
                  return 0; // Optional: Return 0 if the sortBy value is not recognized
              }
            })
            .map((question) => <QuestionCard key={question.id} question={question} />)}

        <MobileCreateButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          +
        </MobileCreateButton>
      </ExploreContainer>
      {showModal && <CreateQuestionModal setShowModal={setShowModal} />}
    </>
  );
}

export default ExplorePage;
