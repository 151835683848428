import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, updateProfile } from "firebase/auth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsQHDP0ijOjGqb8lpGjKUSZhzyg7k3poE",
  authDomain: "ask-app-466fe.firebaseapp.com",
  projectId: "ask-app-466fe",
  storageBucket: "ask-app-466fe.appspot.com",
  messagingSenderId: "417169967610",
  appId: "1:417169967610:web:645cda57c74b7114b7fc43",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function loginWithPopup() {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful login

        console.log("User logged in:", result.user);
      })
      .catch((error) => {
        console.error("Login error:", error);
      });
  }

  function signOut() {
    return auth.signOut();
  }

  function signUp(email, password, displayName) {
    return createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      const user = userCredential.user;
      // Update the user's display name and profile picture URL

      await updateProfile(user, {
        displayName: displayName,
      });
    });
  }

  function getUser() {
    return auth.currentUser;
  }

  const updateUser = (name, avatar) => {
    updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: avatar,
    });
  };

  const updateUsername = (name) => {
    updateProfile(auth.currentUser, {
      displayName: name,
    });
  };

  const updatePhoto = (photo) => {
    updateProfile(auth.currentUser, {
      photoURL: photo,
    });
  };
  // function isAdmin() {
  //   return auth.currentUser.getIdTokenResult().then((idTokenResult) => {
  //     if (!!idTokenResult.claims.admin) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  // function isEditor() {}

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    getUser,
    login,
    signOut,
    signUp,
    updateUser,
    updateUsername,
    updatePhoto,
    loginWithPopup,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
