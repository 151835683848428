import React, { createContext, useContext, useState, useCallback } from "react";
import Toast from "../components/UI/Toast";

interface ToastContextProps {
  showToast: (message: string) => void;
  hideToast: () => void;
}

const ToastContext = createContext<ToastContextProps | null>(null);

const ToastProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const showToast = useCallback((message: string) => {
    setToastMessage(message);
    setTimeout(() => {
      hideToast();
    }, 5000); // Hide the toast after 5 seconds
  }, []);

  const hideToast = useCallback(() => {
    setToastMessage(null);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {toastMessage && <Toast toastMessage={toastMessage} />}
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

interface ToastProps {
  message: string;
}

export { ToastProvider, useToast };
