import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  onToggle: Function;
  value: boolean;
}

const Container = styled.label<{ isActive: boolean }>`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const Checkbox = styled.input`
  display: none;
`;

const SliderDot = styled.span<{ isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  border-radius: 34px;
  background-color: ${(props) => (props.isActive ? "#007AFF" : "#E5E5EA")};
  transition: background-color 0.3s;

  &::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: ${(props) => (props.isActive ? "30px" : "4px")};
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: left 0.3s;
  }
`;

const Slider = ({ onToggle, value }: Props) => {
  const [isActive, setIsActive] = useState(value);

  const toggleButton = () => {
    setIsActive((prevValue) => !prevValue);
    onToggle();
  };
  return (
    <Container isActive={isActive}>
      <Checkbox type="checkbox" checked={isActive} onChange={toggleButton} />
      <SliderDot isActive={isActive} />
    </Container>
  );
};

export default Slider;
