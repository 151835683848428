import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import exit from "../assets/icons/deleteCommentIcon.svg";
import { Question } from "../types";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../contexts/AuthContext";
import { database } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { device } from "../components/UI/Media";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Toast from "./UI/Toast";
import { useToast } from "../contexts/ToastContext";

const ModalContainer = styled.div`
  font-family: "Futura";
  box-sizing: border-box;
  position: fixed;
  max-height: 80vh;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px -3px 5px rgb(58, 58, 58);
  border: 1px solid #333333;
  border-radius: 5px 5px 0 0;
  padding: 0 0.5rem 0.5rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  @media ${device.tablet} {
    max-height: 90vh;
  }
`;
const Scrollbar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  background-color: #f1f1f1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${ModalContainer}:hover & {
    opacity: 0.6;
  }

  /* For Firefox */
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 1080px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  overflow-y: scroll;
`;

const InputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${device.tablet} {
  }
`;

export const Label = styled.label`
  font-size: 0.9rem;
  font-weight: 700;
  color: #5c5c5c;
  margin-bottom: 7px;
`;

const Context = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 0.8rem;
  padding: 0.5rem;
  border: 1px solid rgb(51, 51, 51, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  font-family: "Futura";
  resize: none;
  &::placeholder {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
    font-weight: normal;
  }
`;

export const ModalInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid rgb(51, 51, 51, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  &::placeholder {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
  }

  &.error {
    border-color: red;
  }
`;

const ModalAnswer = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  border: 1px solid #333333;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  position: relative;
  &.error {
    border-color: red;
  }
`;

const AddAnswer = styled.button`
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 900;
  padding: 0.5rem;
  color: white;
  background-color: black;
  border: 1px solid #333333;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  @media ${device.tablet} {
    width: 15rem;
  }
`;

const XButton = styled.img`
  position: absolute;
  top: 45%;
  right: 5px;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const CheckboxLabel = styled.label`
  font-size: 0.8rem;
  margin-left: 0.5rem;
`;

const SelectContainer = styled.div`
  position: relative;
`;

const Select = styled.select`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid rgb(51, 51, 51, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  font-family: "Futura";
  appearance: none;
  background-color: transparent;
  cursor: pointer;
`;

const DropdownArrow = styled.span`
  position: absolute;
  top: 45%;
  right: 0.5rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.25rem 0.25rem 0;
  border-color: #333333 transparent transparent;
  pointer-events: none;
`;

const SubmitButton = styled.button`
  margin-top: 0.75rem;
  width: 100%;
  min-height: 4rem;
  max-height: 4rem;
  color: white;
  border-radius: 5px;
  font-weight: 800;
  font-size: 1.5rem;
  background: linear-gradient(135deg, #4a90e2, #9013fe);
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  outline: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    min-height: 4rem;
    max-height: 4rem;
    width: 15rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    transition: top 0.3s;
    z-index: -1;
  }

  &:hover:before {
    top: 0;
  }
`;

const CloseImg = styled.img`
  z-index: 10;
  cursor: pointer;
  height: 2.5rem;
  border: none;
  padding-top: 0.5rem;
`;

const CloseDiv = styled.div`
  z-index: 10;
  background-color: white;
  padding-top: 0.5rem 0.5rem 0;
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: flex-end;
`;

const CheckBoxInput = styled.input`
  position: relative;
  width: 24px;
  height: 24px;
  appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 2px solid #333333;

  &:checked {
    background-color: #007bff;
    border-color: #007bff;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7) rotate(45deg);
    width: 10px;
    height: 20px;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
    opacity: 0;
  }

  &:checked::before {
    opacity: 1;
  }

  &.error {
    border-color: red;
  }
`;

function CreateQuestionModal({ setShowModal }: { setShowModal: (value: boolean) => void }) {
  const [contextValue, setContextValue] = useState("");
  const [hashtagValues, setHashtagValues] = useState<string[]>([]);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionPublic, setQuestionPublic] = useState<boolean>();
  const [category, setCategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const { showToast } = useToast();

  const [randomQuestion, setRandomQuestion] = useState({ question: "", context: "", hashtags: "" });

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * questionExamples.length);
    const selectedQuestion = questionExamples[randomIndex];
    setRandomQuestion(selectedQuestion);
  }, []);

  const [modalAnswers, setModalAnswers] = useState([
    { id: uuidv4(), answer: "", votes: 0 },
    { id: uuidv4(), answer: "", votes: 0 },
  ]);

  const [isMultipleChoice, setIsMultipleChoice] = useState<boolean>();
  const [isYesNo, setIsYesNo] = useState<boolean>();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setIsMultipleChoice(id === "multipleChoiceCheckbox" && checked);
    setIsYesNo(id === "yesNoCheckbox" && checked);
  };

  const handleVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (id === "public" && checked) {
      setQuestionPublic(true);
    } else if (id === "private" && checked) {
      setQuestionPublic(false);
    }
  };

  const addAnswer = () => {
    setModalAnswers((prevModalAnswers) => [...prevModalAnswers, { id: uuidv4(), answer: "", votes: 0 }]);
  };

  const removeAnswer = (id: string) => {
    setModalAnswers((prevModalAnswers) => prevModalAnswers.filter((answer) => answer.id !== id));
  };

  const questionExamples = [
    {
      question: "Example: Blue or Red?",
      context: "Example: Which color do you prefer?",
      hashtags: "Example: blue, red, colours, favorite",
    },
    {
      question: "Example: Pizza or Pasta?",
      context: "Example: If you could only eat one for the rest of your life?",
      hashtags: "Example: pizza, pasta, italian food, cooking, eat, foodie",
    },
    {
      question: "Example: Dogs or Cats?",
      context: "Example: My roommate says cats are smarter, but I think dogs are more loyal. Which animal do you prefer living with?",
      hashtags: "Example: dogs, cats, animals, pets, lifestyle, favorite",
    },
    {
      question: "Example: What's the best toothpaste?",
      context: "Example: Which toothpaste is the best meaning whitens and cleans your teeth the best with as little harmful chemicals as possible.",
      hashtags: "Example: toothpaste, hygiene, health, oral health, mens health, womens health, brush, teeth",
    },
  ];

  const isValidCheck = () => {
    let isValid = true;
    const newErrorFields = [];

    if (!questionTitle) {
      newErrorFields.push("questionTitle");
      isValid = false;
      setErrorMessage("Please enter a title");
    }

    if (isYesNo === undefined) {
      newErrorFields.push("questionType");
      isValid = false;
      setErrorMessage("Please select a question type");
    }

    if (questionPublic === undefined) {
      newErrorFields.push("visibility");
      isValid = false;
      setErrorMessage("Please select visibility");
    }

    {
      if (isYesNo === false) {
        modalAnswers.forEach((answer) => {
          if (!answer.answer) {
            newErrorFields.push(`answer_${answer.id}`);
            isValid = false;
            setErrorMessage("Please enter valid answers");
          }
        });
      }
    }

    setErrorFields(newErrorFields); // Update the errorFields state with the newErrorFields

    return isValid;
  };

  const handleSubmit = async () => {
    let updatedModalAnswers = modalAnswers;
    if (isYesNo) {
      updatedModalAnswers = [
        { id: uuidv4(), answer: "Yes", votes: 0 },
        { id: uuidv4(), answer: "No", votes: 0 },
      ];
    } else {
      updatedModalAnswers = [...modalAnswers];
    }
    const newQuestion: Question = {
      id: uuidv4(),
      userId: currentUser.uid,
      username: currentUser.displayName,
      question: questionTitle,
      answers: updatedModalAnswers,
      totalVotes: 0,
      date: Date.now(),
      comments: [],
      context: contextValue,
      hashtags: hashtagValues,
      category: category,
      public: questionPublic!,
    };
    try {
      const docRef = doc(database, "Questions", newQuestion.id);
      await setDoc(docRef, newQuestion);
      const usersRef = doc(database, "Users", currentUser.uid);
      const usersSnapshot = await getDoc(usersRef);
      if (usersSnapshot.exists()) {
        let newUser = usersSnapshot.data();
        if (newUser.createdPolls) {
          newUser.createdPolls.push(newQuestion.id);
        } else {
          newUser = { ...newUser, createdPolls: [newQuestion.id] };
        }
        await setDoc(usersRef, newUser);
      }
      setShowModal(false);
    } catch (error) {
      console.error("Error creating question:", error);
    }
    navigator.clipboard.writeText(`https://wonderpoll.io/poll/${newQuestion.id}`);
    showToast("Link copied to clipboard");

    navigate(`/poll/${newQuestion.id}`);
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [errorMessage]);

  return (
    <ModalContainer>
      <CloseDiv>
        <CloseImg
          src={exit}
          onClick={() => {
            setShowModal(false);
          }}
        />
      </CloseDiv>
      <InputContainer>
        <Label>Question</Label>
        <ModalInput
          placeholder={randomQuestion.question}
          onChange={(e) => setQuestionTitle(e.target.value)}
          className={errorFields.includes("questionTitle") ? "error" : ""}
          required
        />
      </InputContainer>
      <InputContainer>
        <Label>Add Context (Optional)</Label>
        <Context placeholder={randomQuestion.context} onChange={(e) => setContextValue(e.target.value)} />
      </InputContainer>

      <InputContainer>
        <Label>Select Category</Label>
        <SelectContainer>
          <Select value={category} onChange={(e) => setCategory(e.target.value)} required>
            <option value="Sports">Sports</option>
            <option value="Food">Food</option>
            <option value="Politics">Politics</option>
            <option value="Health">Health</option>
            <option value="Music">Music</option>
            <option value="TV">TV</option>
            <option value="Technology">Technology</option>
            <option value="Movies">Movies</option>
            <option value="Games">Games</option>
            <option value="Finance">Finance</option>
            <option value="Other">Other</option>
          </Select>
          <DropdownArrow />
        </SelectContainer>
      </InputContainer>

      <InputContainer>
        <Label>Visibility</Label>
        <CheckboxContainer>
          <CheckBoxInput
            type="checkbox"
            id="public"
            checked={questionPublic === true}
            onChange={handleVisibility}
            className={errorFields.includes("visibility") ? "error" : ""}
          />
          <CheckboxLabel htmlFor="public">Public</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBoxInput
            type="checkbox"
            id="private"
            checked={questionPublic === false}
            onChange={handleVisibility}
            className={errorFields.includes("visibility") ? "error" : ""}
          />
          <CheckboxLabel htmlFor="private">Private</CheckboxLabel>
        </CheckboxContainer>
      </InputContainer>
      <InputContainer>
        <Label>Question Type</Label>
        <CheckboxContainer>
          <CheckBoxInput
            type="checkbox"
            id="yesNoCheckbox"
            checked={isYesNo}
            onChange={handleCheckboxChange}
            className={errorFields.includes("questionType") ? "error" : ""}
          />
          <CheckboxLabel htmlFor="yesNoCheckbox">Yes or No</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBoxInput
            type="checkbox"
            id="multipleChoiceCheckbox"
            checked={isMultipleChoice}
            onChange={handleCheckboxChange}
            className={errorFields.includes("questionType") ? "error" : ""}
          />
          <CheckboxLabel htmlFor="multipleChoiceCheckbox">Multiple Choice</CheckboxLabel>
        </CheckboxContainer>
      </InputContainer>

      {isYesNo ? (
        <>
          <InputContainer>
            <ModalAnswer placeholder="Yes" disabled className={errorFields.includes("answer_0") ? "error" : ""} />
          </InputContainer>
          <InputContainer>
            <ModalAnswer placeholder="No" disabled />
          </InputContainer>
        </>
      ) : isMultipleChoice ? (
        <>
          <InputContainer>
            <Label>Answers</Label>
            {modalAnswers.map((answer) => (
              <InputContainer key={answer.id}>
                <ModalAnswer
                  placeholder={`Answer ${modalAnswers.indexOf(answer) + 1}`}
                  onChange={(e) =>
                    setModalAnswers((prevModalAnswers) => prevModalAnswers.map((a) => (a.id === answer.id ? { ...a, answer: e.target.value } : a)))
                  }
                  className={errorFields.includes(`answer_${answer.id}`) ? "error" : ""}
                />
                <XButton src={exit} onClick={() => removeAnswer(answer.id)} />
              </InputContainer>
            ))}
          </InputContainer>
          <AddAnswer onClick={() => addAnswer()}>Add Answer</AddAnswer>
        </>
      ) : null}

      <InputContainer>
        <Label>Add Tags (Optional)</Label>
        <ModalInput
          placeholder={randomQuestion.hashtags}
          onChange={(e) => setHashtagValues(e.target.value.split(",").map((tag) => tag.trim()))}
        ></ModalInput>
      </InputContainer>

      <SubmitButton
        onClick={async (e) => {
          e.preventDefault();
          if (isValidCheck()) {
            handleSubmit();
          }
        }}
      >
        Submit Question
      </SubmitButton>

      {errorMessage && <Toast toastMessage={errorMessage} />}
      <Scrollbar />
    </ModalContainer>
  );
}

export default CreateQuestionModal;
