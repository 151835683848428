import { doc, getDoc, updateDoc } from "firebase/firestore";
import { database } from "../firebase";
import { Comment } from "../types";
import { RefObject } from "react";
import { v4 as uuidv4 } from "uuid";

export const handleCommentDelete = async (questionId: string, commentId: string) => {
  try {
    const docRef = doc(database, "Questions", questionId);
    const documentSnapshot = await getDoc(docRef);

    const questionData = documentSnapshot.data();

    const updatedComments = deleteComment(questionData?.comments, commentId);

    const updatedQuestion = { ...questionData, comments: updatedComments };

    await updateDoc(docRef, updatedQuestion);

    console.log("Comment deleted successfully!");
  } catch (error) {
    console.error("Error deleting comment:", error);
  }
};

const deleteComment = (comments: Comment[], commentId: string) => {
  return comments.filter((comment) => {
    if (comment.id === commentId) {
      return false; // Exclude the comment from the updated comments
    } else if (comment.children) {
      comment.children = deleteComment(comment.children, commentId);
    }
    return true; // Include the comment in the updated comments
  });
};

export const handleCommentReply = async (questionId: string, currentUser: any, replyRef: RefObject<HTMLInputElement>, commentId: string) => {
  try {
    const docRef = doc(database, "Questions", questionId);
    const documentSnapshot = await getDoc(docRef);

    const questionData = documentSnapshot.data();
    const newComment = {
      username: currentUser.displayName,
      content: replyRef.current!.value,
      date: Date.now(),
      userId: currentUser.uid,
      id: uuidv4(),
      children: [],
      likes: 0,
      likedByUsers: [],
    };

    const updatedComments = addReplyToComment(questionData?.comments, commentId, newComment);

    const updatedQuestion = { ...questionData, comments: updatedComments };

    await updateDoc(docRef, updatedQuestion);

    console.log("Reply added successfully!");
  } catch (error) {
    console.error("Error adding reply:", error);
  }
};

const addReplyToComment = (comments: Comment[], commentId: string, newComment: Comment) => {
  return comments.map((comment) => {
    if (comment.id === commentId) {
      if (comment.children) {
        comment.children.push(newComment);
      } else {
        comment.children = [newComment];
      }
    } else if (comment.children) {
      comment.children = addReplyToComment(comment.children, commentId, newComment);
    }
    return comment;
  });
};

export function countComments(comment: Comment): number {
  let count = 1; // Start with 1 to include the current comment

  if (comment.children.length > 0) {
    for (const childComment of comment.children) {
      count += countComments(childComment); // Recursively count comments of children
    }
  }

  return count;
}
