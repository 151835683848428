import styled from "styled-components";
import { useAuth } from "../contexts/AuthContext";
import { useRef } from "react";
import GoogleSignIn from "../assets/icons/google-signin.png";
import { Label, ModalInput } from "./CreateQuestionModal";
import Button from "./UI/Button";
import { useNavigate } from "react-router-dom";

const Modal = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  max-width: 90%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  background-color: #ffffff;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.2);
  border: 3px solid #333333;
  border-radius: 5px 5px 5px 5px;
  padding: 1rem;
  z-index: 10;

  @media (min-width: 786px) {
    left: 55%;
    top: 25%;
    transform: translate(-50%, -25%);
    max-width: 50%;
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  label {
    margin-bottom: 4px;
  }

  input {
    margin-bottom: 0.5rem;
  }

  button {
    margin: 0.25rem;
  }

  p {
    margin: 0.5rem 0;
    cursor: pointer;
    text-align: center;
    color: blue;
    text-decoration: underline;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const Image = styled.img`
  cursor: pointer;
  height: 3rem;
  margin: auto;
`;

type Props = {
  handleSignUp: Function;
  setShowLoginModal: Function;
};

function LoginModal({ handleSignUp, setShowLoginModal }: Props) {
  const { login, loginWithPopup } = useAuth();
  const navigate = useNavigate();
  const EmailRef = useRef<HTMLInputElement>(null);
  const PasswordRef = useRef<HTMLInputElement>(null);

  return (
    <Modal>
      <InfoContainer>
        <h2>Login</h2>

        <Label>Email</Label>
        <ModalInput type="email" placeholder="Enter Email" ref={EmailRef} />

        <Label>Password</Label>
        <ModalInput type="Password" placeholder="Enter Password" ref={PasswordRef} />

        <Button
          onClick={async () => {
            await login(EmailRef.current!.value, PasswordRef.current!.value);
            setShowLoginModal(false);
            navigate("/explore");
          }}
        >
          Submit
        </Button>

        <Image
          src={GoogleSignIn}
          onClick={async () => {
            await loginWithPopup();
            setShowLoginModal(false);
            navigate("/explore");
          }}
          alt="Google sign in button"
        />

        <p
          onClick={() => {
            handleSignUp();
          }}
        >
          Create Account
        </p>
      </InfoContainer>
    </Modal>
  );
}
export default LoginModal;
