import { useState } from "react";
import styled from "styled-components/macro";
import CreateQuestionModal from "../components/CreateQuestionModal";
import { device } from "../components/UI/Media";

import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuth } from "../contexts/AuthContext";

const Container = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.tablet} {
    margin-top: 0;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  h1 {
    padding: 1rem;
  }
  h2 {
    padding: 1rem;
    font-weight: 300;
  }
  p {
    padding: 2rem 0rem 2rem 0;
  }
  span {
  }
`;

const Signup = styled.span`
  color: #065dff;
  text-decoration: underline;
  cursor: pointer;
`;

const CTAButton = styled.button`
  margin-top: 0.75rem;
  width: 15rem;
  min-height: 4rem;
  max-height: 4rem;
  color: white;
  border-radius: 5px;
  font-weight: 800;
  font-size: 1.5rem;
  background: linear-gradient(135deg, #4a90e2, #9013fe);
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  outline: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    min-height: 4rem;
    max-height: 4rem;
    width: 15rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    transition: top 0.3s;
    z-index: -1;
  }

  &:hover:before {
    top: 0;
  }
`;

const HomePage = () => {
  const { currentUser, loginWithPopup } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    await loginWithPopup();
  };

  const askAQuestion = async () => {
    if (currentUser) {
      setShowModal(true);
    } else {
      handleLogin();
    }
  };

  return (
    <Container>
      <Main>
        <h1>Public voting made easy.</h1>
        <h2>Ask. Vote. Discuss. Earn Karma.</h2>
        <CTAButton onClick={() => askAQuestion()}>Create Poll</CTAButton>
        <CTAButton onClick={() => navigate(`/explore`)}>Explore Polls</CTAButton>
        <div>
          <p>
            <Signup
              onClick={async () => {
                await handleLogin();
                navigate("/explore");
              }}
            >
              Sign up
            </Signup>{" "}
            automatically with your email. It's fast and free.
          </p>
        </div>
      </Main>
      {showModal && <CreateQuestionModal setShowModal={setShowModal} />}
    </Container>
  );
};

export default HomePage;
