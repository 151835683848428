import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ExplorePage from "./pages/ExplorePage";
import PollPage from "./pages/PollPage";
import DashboardPage from "./pages/DashboardPage";
import AccountPage from "./pages/AccountPage";
import styled from "styled-components";
import HomePage from "./pages/Homepage";
import MobileNavbar from "./components/MobileNavbar";
import Signup from "./pages/Signup";
import { useAuth } from "./contexts/AuthContext";
import { useState } from "react";
import { device } from "./components/UI/Media";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100vh;
  max-height: 100svh;
  overflow-y: scroll;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

function App() {
  const { currentUser } = useAuth();

  return (
    <BrowserRouter>
      <PageContainer>
        <MobileNavbar />
        <Navbar />
      
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/poll/:id" element={<PollPage />} />
          <Route path="/signup" element={<Signup />} />
          {currentUser && <Route path="/dashboard" element={<DashboardPage />} />}
          {/* <Route path="/account" element={<AccountPage />} /> */}
        </Routes>
      </PageContainer>
    </BrowserRouter>
  );
}

export default App;
