import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { Comment, Question, UserVote } from "../types";
import Answers from "./Answers";
import { Link, useLocation } from "react-router-dom";
import { getTimeSinceCommentPosted } from "../Utils/dateUtil";
import CommentCard from "./CommentCard";
import { useAuth } from "../contexts/AuthContext";
import { doc, getDoc, deleteDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { database } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import TaskBar from "./TaskBar";
import saveIcon from "../assets/icons/bookmark.svg";
import saveIconRed from "../assets/icons/bookmark-active.svg";
import Toast from "./UI/Toast";
import ThreeDotsIcon from "./UI/ThreeDotsIcon";

interface QuestionCardProps {
  question: Question;
  // handleDelete: (id: string) => void; 
}


const Card = styled.div`
  font-family: "Helvetica";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: white;
  transition: 1s;
  margin: 1rem 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  width: 100%;
  height: fit-content;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  @media (min-width: 768px) {
    min-width: 450px;
    max-width: 600px;
    border-radius: 8px;
  }
  @media (min-width: 1268px) {
    max-width: 1000px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 768px) {
    flex-direction: column;
  }
`;

const DateText = styled.p`
  opacity: 0.5;
  font-size: 0.65rem;
  margin-top: 3px;
  width: 100%;
  text-align: center;
  padding: 0px 10px 20px 10px;
  @media (min-width: 768px) {
    padding: 0 0 1rem 0;
  }
  span {
    position: relative;
    cursor: pointer;
    font-weight: 700;
    background: linear-gradient(135deg, #4a90e2, #9013fe);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 1px;
        background-color: #4a90e2;
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 1s ease-in-out;
      }
    }
  }
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  color: #3e3e3e;
  padding: 1rem 1rem 0 1rem;

  @media (min-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

const SubmitCommentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 0.5rem;
  /* padding: 0.5rem; */
`;
const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid rgb(51, 51, 51, 0.2);
  border-radius: 0.25rem;

  &::placeholder {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
  }
  @media (min-width: 768px) {
    width: 88%;
    align-items: flex-start;
  }
`;
const SubmitCommentButton = styled.button`
  margin-left: 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 900;
  padding: 0.5rem;
  height: 2.25rem;
  color: white;
  background-color: #007bcd;
  border: 1px solid #333333;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: #0061a2;
  }
  @media (min-width: 768px) {
    width: 15%;
    margin-right: 1.5rem;
  }
`;
const TitleBin = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;

  p {
    text-align: left;
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: flex-start;
  }
  @media (min-width: 768px) {
    /* Add any additional styles for larger screens if needed */
  }
`;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const BookMarkImg = styled.img`
  cursor: pointer;
  height: 2.4rem;
  align-self: flex-start; // Add this line

  &:hover {
    transform: translateY(-2px);
  }
`;

const QuestionTitleText = styled.h2`
  font-size: 1.25rem;
  margin-top: 0.25rem;
  text-align: left;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
  }
`;

const Context = styled.p`
  font-size: 0.75rem;
  padding: 5px 0;
  opacity: 0.5;
`;
const Subheader = styled.h5`
  font-size: 1rem;
  font-weight: 800;
  padding-top: 1rem;
  font-size: 1.25rem;
  margin-top: 0.25rem;
  text-align: left;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const BinRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 15px 0 15px;
  position: relative;

  img {
    margin-right: 10px;
    align-self: flex-start;
  }

  & > * {
    margin-left: 15px;
  }

  & > :first-child {
    margin-left: 0;
  }
`;

const CountComments = styled.p`
  font-size: 0.7rem;
  margin: 0;
  position: absolute;
  top: 8.5px;
  left: 28.5px;
  z-index: 2;
  cursor: pointer;
`;

const Hotlink = styled(Link)`
  font-size: 0.7rem;
  color: #333333;
  border: solid 1px #333333;
  border-radius: 15px;
  padding: 5px 7px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: #1d1c1c;
    color: white;
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const CopyLink = styled.p`
  font-size: 0.7rem;
  color: #333333;
  border: solid 1px #333333;
  border-radius: 15px;
  padding: 5px 7px;
  text-decoration: none;
  /* margin-left: 15px; */
  cursor: pointer;
  &:hover {
    background-color: #333333;
    color: white;
  }
`;

const ShareToSocial = styled.button`
  font-size: 0.7rem;
  color: #333333;
  background-color: white;
  border: solid 1px #333333;
  border-radius: 15px;
  padding: 5px 7px;
  text-decoration: none;
  /* margin-left: 15px; */
  width: 50px;
  cursor: pointer;
  &:hover {
    background-color: #333333;
    color: white;
  }
`;

const Line = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #4a90e2;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: left;
  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #4a90e2;
    transition: transform 0.3s ease-in-out;
    transform-origin: right;
  }
`;

const QuestionCard: React.FC<QuestionCardProps> = ({ question  }) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [share, setShare] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasUserVoted, setHasUserVoted] = useState<boolean>(false);
  const [hasUserBookmarked, setHasUserBookmarked] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState("");



  const { currentUser } = useAuth();
  const cardRef = useRef<HTMLDivElement>(null);

  const location = useLocation();



  useEffect(() => {
    let newVotes = 0
    question.answers?.forEach((answer => {
      newVotes += answer.votes
    }))
    const questionRef = doc(database, 'Questions', question.id);

    // Update the 'votes' field in the Firestore document
    updateDoc(questionRef, {
      totalVotes: newVotes,
    })
      .then(() => {
        console.log('Document updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
  }, [question]);

  useEffect(() => {
    if (currentUser) {
      const userRef = doc(database, "Users", currentUser.uid);

      const unsubscribe = onSnapshot(userRef, (userSnapshot) => {
        if (userSnapshot.exists()) {
          const votes = userSnapshot.data().votes;
          const votedAnswer = votes.find((vote: UserVote) => vote.questionId === question.id);
          
          if (votedAnswer) {
            setHasUserVoted(true);
          } else {
            setHasUserVoted(false);
          }

          if (userSnapshot.data().saved) {
            const savedQuestions = userSnapshot.data().saved;
            const hasUserSaved = savedQuestions.find((savedId: string) => savedId === question.id);

            if (hasUserSaved) {
              setHasUserBookmarked(true);
            } else {
              setHasUserBookmarked(false);
            }
          }
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [currentUser]);

  function countComments(comments: Comment[]): number {
    let count = 0;

    for (const comment of comments) {
      count++; // Increment count for each comment in the array

      if (comment.children.length > 0) {
        count += countComments(comment.children); // Recursively count comments in children
      }
    }

    return count;
  }

  const handleSave = async (id: string) => {
    const usersRef = doc(database, "Users", currentUser.uid);

    const usersSnapshot = await getDoc(usersRef);

    if (usersSnapshot.exists()) {
      let newUser = usersSnapshot.data();

      if (newUser.saved) {
        if (newUser.saved.includes(id)) {
          newUser.saved = newUser.saved.filter((savedId: string) => {
            return savedId !== id;
          });
        } else {
          newUser.saved.push(id);
        }
      } else {
        newUser = { ...newUser, saved: [id] };
      }

      await setDoc(usersRef, newUser);
    }
  };

  const handleCopy = (id: string) => {
    navigator.clipboard
      .writeText(`https://wonderpoll.io/poll/${id}`)
      .then(() => {
        console.log("Text copied to clipboard:", `https://wonderpoll.io/poll/${id}`);
        // You can also show a success message or perform any additional actions here
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
        // Handle any errors that occurred during copying
      });
  };


  const isCreator = currentUser && currentUser.uid === question.userId;

  const handleDelete = async () => {
    if (currentUser && currentUser.uid === question.userId) {
      try {
        await deleteDoc(doc(database, "Questions", question.id));
        console.log("Document successfully deleted!");
        setMenuOpen(false); // Close the menu after deletion
      } catch (error) {
        console.error("Error removing document: ", error);
      }
    } else {
      console.log("You are not authorized to delete this question.");
    }
  };
  

  const handleReportQuestion = () => {
    console.log("Flag this question to admins");
    setMenuOpen(false); // Close the menu after reporting
  };





  return (
    <Card ref={cardRef}>
      {showToast && <Toast toastMessage="Link copied to clipboard" />}
      <Container>
        <Divider>
          <TitleBin>
            <FlexCol>
              <QuestionTitleText>{question.question}</QuestionTitleText>
              {question.context && <Context>{question.context}</Context>}
            </FlexCol>
            {currentUser && (
              <>
                <BookMarkImg
                  src={hasUserBookmarked ? saveIconRed : saveIcon}
                  onClick={() => {
                    handleSave(question.id);
                  }}
                ></BookMarkImg>
              </>
            )}
          </TitleBin>
        </Divider>

        <BinRow>
          {!location.pathname.includes("/poll") && <Hotlink to={`/poll/${question.id}`}>Read Comments: {countComments(question.comments)}</Hotlink>}
          <CopyLink
            onClick={() => {
              if (!showToast) {
                handleCopy(question.id);
                setShowToast(true);

                setTimeout(() => {
                  setShowToast(false);
                }, 2000);
              }
            }}
          >
            Copy Link
          </CopyLink>
          <ShareToSocial onClick={() => setShare(!share)}>Share</ShareToSocial>
        </BinRow>

        {share && <TaskBar question={question} />}
        {question.answers && (
          <Answers
            totalVotes={question.totalVotes}
            answers={question.answers!}
            questionId={question.id}
            hasUserVoted={hasUserVoted}
            questionRef={cardRef}
          />
        )}
      </Container>

      {location.pathname.includes("/poll") && (
        <CommentsContainer>
          {question.comments.map((comment, index) => {
            return <CommentCard key={index} comment={comment} questionComments={question.comments} questionId={question.id} />;
          })}
          {currentUser && ( 
            <SubmitCommentContainer>
              <Input ref={inputRef} onChange={(e) => setCommentValue(e.target.value)} placeholder="Add a comment" />
              <SubmitCommentButton
                disabled={commentValue === ""}
                onClick={async () => {
                  const docRef = doc(database, "Questions", question.id);
                  const documentSnapshot = await getDoc(docRef);

                  const questionData = documentSnapshot.data();
                  const newComment: Comment = {
                    username: currentUser.displayName,
                    date: Date.now(),
                    content: inputRef.current!.value,
                    userId: currentUser.uid,
                    id: uuidv4(),
                    children: [],
                    likes: 0,
                    likedByUsers: [],
                  };
                  const newQuestion = { ...questionData, comments: [...question.comments, newComment] };
                  updateDoc(docRef, newQuestion);
                }}
              >
                Post
              </SubmitCommentButton>
            </SubmitCommentContainer>
          )}
        </CommentsContainer>
      )}
             <DateText>
        <span onClick={() => console.log("hello")}>{question.username}</span> published {getTimeSinceCommentPosted(question.date)}
        {/* <Line /> */}
      </DateText>

          <ThreeDotsIcon
      isOpen={isMenuOpen}
      toggleMenu={() => setMenuOpen(!isMenuOpen)}
      onDelete={handleDelete}
      onReportQuestion={handleReportQuestion}
      isCreator={isCreator} // Pass the isCreator prop here
    />
    </Card>
  );
};

export default QuestionCard;
