export function getTimeSinceCommentPosted(commentDate: number): string {
  const now: Date = new Date();
  const elapsed: number = now.getTime() - commentDate;

  // Convert milliseconds to minutes, hours, days, weeks, months, and years
  const minutes: number = Math.floor(elapsed / (1000 * 60));
  const hours: number = Math.floor(elapsed / (1000 * 60 * 60));
  const days: number = Math.floor(elapsed / (1000 * 60 * 60 * 24));
  const weeks: number = Math.floor(elapsed / (1000 * 60 * 60 * 24 * 7));
  const months: number = Math.floor(elapsed / (1000 * 60 * 60 * 24 * 30));
  const years: number = Math.floor(elapsed / (1000 * 60 * 60 * 24 * 365));

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `just now`;
  }
}
