import React from 'react';
import styled, { keyframes, StyledComponentPropsWithRef } from 'styled-components';

const Loading: React.FC = () => (
  <SnakeAnimation>
    <Dot delay="0.1s" />
    <Dot delay="0.2s" />
    <Dot delay="0.3s" />
    <Dot delay="0.4s" />
    <Dot delay="0.5s" />
  </SnakeAnimation>
);

const dotAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

type DotProps = {
  delay: string;
};

const Dot = styled.div<DotProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00000088;
  animation: ${dotAnimation} 1s ease-in-out infinite;
  animation-delay: ${(props) => props.delay};
`;

const SnakeAnimation = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export default Loading;
