import React from 'react';
import styled from 'styled-components';

const TaskContainer = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 5px 5px;
    img {
        min-width: 75px;
        min-height: 50px;
        max-width: 75px;
        &:hover {
            cursor: pointer;
        }
    }
`;

interface TaskProps {
  imgSrc: string;
  onClick: () => void;
}

const Task: React.FC<TaskProps> = ({ imgSrc, onClick }) => {
  return (
    <TaskContainer onClick={onClick}>
      <img src={imgSrc} alt="Task" />
    </TaskContainer>
  );
};

export default Task;
