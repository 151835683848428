import { AnyRecord } from "dns";
import React from "react";
import styled from "styled-components/macro";

export const Button = styled.button`
  background-color: black;
  color: white;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 3rem;
  font-weight: 900;
  margin-top: 0.4rem;

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;

export default Button;
