import { useEffect, useState } from "react";
import { collection, onSnapshot, Unsubscribe, query, where } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { database } from "../../firebase";
import { Question } from "../../types";

const useFetchPolls = (collectionPath: string, searchType?: "category" | "hashtags", searchValue?: string): Question[] => {
  const [data, setData] = useState<Question[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;

    const collectionRef = collection(database, collectionPath);

    // Add the filter for 'public' property
    let filteredQuery = query(collectionRef, where("public", "==", true));

    if (searchType && searchValue) {
      filteredQuery = query(collectionRef, where(searchType, "==", searchValue), where("public", "==", true));
    } else {
      filteredQuery = query(collectionRef, where("public", "==", true));
    }

    unsubscribe = onSnapshot(filteredQuery, (snapshot) => {
      const documents = snapshot.docs.map((doc) => doc.data() as Question);
      setData(documents);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [collectionPath, searchType, currentUser, searchValue]);

  return data;
};

export default useFetchPolls;
