import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";

import { auth } from "../firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import CreateQuestionModal from "./CreateQuestionModal";

import folder from "../../src/assets/icons/folder.svg";
import folderOpen from "../../src/assets/icons/folder-open.svg";
import create from "../../src/assets/icons/create-ballot.svg";
import home from "../../src/assets/icons/home.svg";
import explore from "../../src/assets/icons/explore.svg";
import dashboard from "../../src/assets/icons/dashboard.svg";
import logout from "../../src/assets/icons/logout.svg";
import SignUpModal from "./SignUpModal";
import LoginModal from "./LoginModal";

const NavbarContainer = styled.div`
  position: sticky;
  display: flex;
  right: 0;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border-bottom: solid 3px black;
  border-top: solid 3px white;
  width: 100%;
  z-index: 10001;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media only screen and (min-width: 320px) {
    padding: 0.5rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const Title = styled.h1`
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-self: flex-start;
  padding-left: 1rem;
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  width: 100%;
`;

const StyledLogoLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: white;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuItem = styled.div`
  background-color: #181818;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #f7f7f7;
  font-size: 1rem;
  font-weight: bold;
  padding: 2rem 2rem;
  border: none;
  border-bottom: solid 1px #4b4b4b;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #343434;
  }
`;

const MobileMenuModal = styled.div`
  position: fixed;
  box-sizing: border-box;
  background-color: #181818;
  width: 100%;
  top: 3.15rem;
  right: 0;
  padding: 0;
  margin: 0;
  height: 100vh;
  z-index: 1000;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  overflow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignOutModal = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  max-width: 400px; /* Adjust the max-width as needed */
  height: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.2);
  border: 3px solid #333333;
  border-radius: 5px 5px 5px 5px;
  padding: 1rem;
  z-index: 100000;
  button {
    margin: 0.25rem;
  }
`;
const BurgerMenu = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  height: 30px;
`;

const FolderIcon = styled.img`
  width: 40px;
  height: 40px;
  padding-right: 5px;
  cursor: pointer;
`;

const MobileIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 7px;
  justify-self: center;
  align-self: center;
  cursor: pointer;
`;

const MobileNavbar: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [activeBurgerMenu, setActiveBurgerMenu] = useState(false);

  const navMenuModalRef = useRef<HTMLDivElement>(null);
  const menuItemRef = useRef<HTMLButtonElement>(null);
  const { currentUser, signOut, loginWithPopup } = useAuth();
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentUser && currentUser.displayName) {
      setUsername(currentUser.displayName);
    }
  }, [currentUser]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navMenuModalRef.current &&
        menuItemRef.current &&
        !navMenuModalRef.current.contains(event.target as Node) &&
        !menuItemRef.current.contains(event.target as Node)
      ) {
        setShowSignOutModal(false);
      }
    };

    const handleWindowClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowSignOutModal(false);
      }
    };

    if (showSignOutModal) {
      window.addEventListener("click", handleWindowClick);
      window.addEventListener("keydown", handleEscapeKeyPress);
    }

    return () => {
      window.removeEventListener("click", handleWindowClick);
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [showSignOutModal]);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleSignUp = () => {
    setShowLoginModal(false);
    setShowSignUpModal(true);
  };

  return (
    <>
      <NavbarContainer>
        <StyledLogoLink to="/">
          <Title>WonderPoll</Title>
        </StyledLogoLink>
        <BurgerMenu onClick={() => setActiveBurgerMenu(!activeBurgerMenu)}>
          <FolderIcon src={activeBurgerMenu ? folderOpen : folder} alt="folder icon"></FolderIcon>
        </BurgerMenu>

        {activeBurgerMenu && (
          <MobileMenuModal>
            <Menu>
              <MenuItem>
                <StyledLink onClick={() => setActiveBurgerMenu(false)} to="/">
                  Home<MobileIcon src={home} alt="home icon"></MobileIcon>
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink onClick={() => setActiveBurgerMenu(false)} to="/explore">
                  Explore<MobileIcon src={explore} alt="explore icon"></MobileIcon>
                </StyledLink>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleModal();
                  setActiveBurgerMenu(false);
                }}
              >
                Create <MobileIcon src={create} alt="create poll icon"></MobileIcon>
              </MenuItem>
              {!username && (
                <MenuItem
                  onClick={() => {
                    setActiveBurgerMenu(false);
                    setShowLoginModal(true);
                  }}
                >
                  Login
                </MenuItem>
              )}
              {username && (
                <>
                  <MenuItem>
                    <StyledLink onClick={() => setActiveBurgerMenu(false)} to="/dashboard">
                      Dashboard<MobileIcon src={dashboard} alt="dashboard icon"></MobileIcon>
                    </StyledLink>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    Logout <MobileIcon src={logout} alt="logout icon"></MobileIcon>
                  </MenuItem>
                  {showModal && (
                    <SignOutModal>
                      <p>Are you sure you want to sign out?</p>
                      <div>
                        <button
                          onClick={async () => {
                            await signOut();
                            navigate(0);
                          }}
                        >
                          Sign Out
                        </button>
                        <button
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </SignOutModal>
                  )}
                </>
              )}
            </Menu>
          </MobileMenuModal>
        )}
      </NavbarContainer>

      {showSignUpModal && <SignUpModal setShowSignUpModal={setShowSignUpModal} />}
      {showLoginModal && <LoginModal handleSignUp={handleSignUp} setShowLoginModal={setShowLoginModal} />}
      {showModal && <CreateQuestionModal setShowModal={setShowModal} />}
    </>
  );
};

export default MobileNavbar;
