import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Button from "./Button";
import exit from "../../assets/icons/deleteCommentIcon.svg";

const bounceAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0);
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const Icon = styled.div`
  box-sizing: border-box;
  width: 7px;
  height: 7px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #a3a3a3;
  margin-bottom: 5px;
  cursor: pointer;
  animation: ${bounceAnimation} 0.4s ease-in-out;
`;

const MenuModal = styled.div<{ isOpen: boolean }>`
  box-shadow: 0px -3px 5px rgb(58, 58, 58);
  position: fixed;
  bottom: -60px;
  border-top: solid 2px black;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
  padding: 1rem;
  height: ${({ isOpen }) => (isOpen ? "40%" : "0")};
  background-color: #ffffff;
  transition: height 0.3s ease-in-out;
`;

const CloseImg = styled.img`
  z-index: 10;
  cursor: pointer;
  height: 2.5rem;
  border: none;
  padding-top: .5rem;
`;

const CloseDiv = styled.div`
  z-index: 10;
  background-color: white;
  padding-top: 0.5rem 0.5rem 0;
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: flex-end;
`;

const DeleteButton = styled(Button)`

  justify-self: center;
`;

const ReportButton =  styled(Button)`
  justify-self: center;
  margin-bottom: 2rem;
`;

interface ThreeDotsIconProps {
  isOpen: boolean;
  toggleMenu: () => void;
  isCreator: boolean; // Add the isCreator prop to check if the user is the creator of the question
  onDelete: () => Promise<void>; // Update the type to indicate a promise
  onReportQuestion: () => void;
}

function ThreeDotsIcon({
  isOpen,
  toggleMenu,
  isCreator,
  onDelete,
  onReportQuestion,
}: ThreeDotsIconProps) {
  const handleIconClick = () => {
    toggleMenu();
  };

  return (
    <Container onClick={handleIconClick}>
      <Icon />
      <Icon />
      <Icon />
      <MenuModal isOpen={isOpen}>
       <CloseDiv>
        <CloseImg
          src={exit}
          onClick={() => {
            toggleMenu();
          }}
        />
      </CloseDiv>
        {isCreator && <DeleteButton onClick={onDelete}>Delete Question</DeleteButton>}
        <ReportButton onClick={onReportQuestion}>Report Question</ReportButton>
      </MenuModal>
    </Container>
  );
}

export default ThreeDotsIcon;
